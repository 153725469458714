<template>
    <div class="flex flex-col" style="width: 99%;">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div class="overflow-hidden">
                    <table class="min-w-full text-left text-sm font-light">

                        <thead class="border-b font-bold dark:border-neutral-500 dark:bg-neutral-600">

                        <tr v-if="!rows || !rows.length">

                            <th  scope="col" class="px-6 py-4" v-for="(field, findex) in fields" v-bind:key="findex" v-bind:style="field.header.style" v-bind:class="field.header.class">{{ field.header.text }}
                                <i v-if="field.header.icon && strEval(field.header.icon.eval)" v-bind:style="field.header.icon.style" v-bind:class="field.header.icon.class">
                                    {{ field.header.icon.text }}</i>
                            </th>

                        </tr>
                        <tr v-for="(row, rindex) in rows" v-bind:key="rindex">

                            <th v-for="(field, frindex) in row" v-bind:key="frindex" v-bind:colspan="field.header.colspan" v-bind:rowspan="field.header.rowspan" v-bind:style="field.header.style" v-bind:class="field.header.class">{{ field.header.text }}
                                <i v-if="field.header.icon && strEval(field.header.icon.eval)" v-bind:style="field.header.icon.style" v-bind:class="field.header.icon.class">
                                    {{ field.header.icon.text }}</i>
                            </th>

                        </tr>
                        </thead>

                        <tbody>

                            <ListItem v-for="(item, i) in items" 
                                v-bind:fields="fields"
                                v-bind:item="item"
                                v-bind:index="i"
                                v-on:on-click-item="onClickItem"
                                v-on:on-dblclick-item="onDblClickItem"
                                v-on:on-line-button-click="onLineButtonClick"
                                :key="JSON.stringify(item)"
                            />

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
import ListItem from './ListItem.vue';

export default {
    
    props: {

        fields: { type: Array },
        rows: { type: Array },
        items: { type: Array }

    },

    components:{
        ListItem    
    },

    mounted() {

    },

    methods: {

        strEval(sd) {

            return eval(sd)
        
        },

        onClickItem(id) {

            this.items.forEach(element => {

                element.selected = element.id == id

            });

            this.$emit('on-click-item', this.items.filter(record => record.id == id)[0]);

        },

        onDblClickItem(id) {

            this.items.forEach(element => {

                element.selected = element.id == id

            });

            this.$emit('on-dblclick-item', this.items.filter(record => record.id == id)[0]);

        },

        onLineButtonClick(...arg){
            
            this.$emit('on-line-button-click', arg);

        }

    }
}
</script>

<style scoped>

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    table.striped>tbody>tr:nth-child(odd).selected {
        
        background-color: #2196f3;
        color: white;

      }

    .scrfull {
    
        display: table-cell;
        
    }

    .scr1024 {
    
        display: none;
        
    }


      @media screen and (max-width: 1024px) {

        
        table thead {

            display: none;

        }


        .scrfull {
        
            display: none;
            
        }

        .scr1024 {
        
            display: block;
            
        }




      }


</style>

