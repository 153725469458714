<template>

<header>

<NavigationBar
    v-bind:settings="nav_bar_settings"
    v-on:on-click-save-order="saveOrder()"
/>


</header>



<ErrorBar
    v-bind:is_error="error.visible"
    v-bind:error_message="error.message"
    v-on:remove-error="error.visible = false"      

/>

<div>

    <h2 class="text-2xl font-medium leading-tight mt-3 text-left">
        <span
            class="inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"
            >Выберите адрес:</span
        >
        
    </h2>    

</div>

<div v-if="loading">
            <LoaderCircle />
    </div>
    <div v-else-if="items.length">

        <ListItems 
            v-bind:fields="fields" 
            v-bind:items="items" 
            v-on:on-click-item="onClickItem"
            v-on:on-dblclick-item="onDblClickItem"
            v-on:on-line-button-click="onLineButtonClick"
        />

    </div>
    <p v-else>нет адресов</p>




</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'
import LoaderCircle from '@/components/LoaderCircle.vue';
import ListItems from '@/components/list/ListItems.vue';
import datestr from '@/datestr';

import tables from '@/tables';

export default {
    data() {
        return {
            user: {},
            user_settings: [],
            error: {
                visible: false,
                message: ""
            },
            nav_bar_settings: {
              left: [ navigation.home, navigation.orders ],
              right: [ 
                navigation.logout
                ]              
            },

            table: tables.product_set_users,

            loading: true,

            fields: [
                { 
                    header: { text: '№', style: 'width: 2%;', class: 'text-center scrfull'}, 
                    item: { eval: 'item.ln + 1', class: 'text-center scrfull'} 
                },
                { 
                    header: { text: 'Контрагент', style: '', class: 'text-center scrfull' }, 
                    item: { eval: 'item.contractor_id_str', class: ' scrfull' }
                },
                    { 
                        header: { text: 'Контрагент', style: '', class: 'text-center scr1024' }, 
                        item: { eval: '(item.ln + 1) + \', \' + item.contractor_id_str', 
                        class: 'text-center  scr1024' }
                    },
                { 
                    header: { text: 'Адрес', style: '', class: 'text-center scrfull' }, 
                    item: { eval: 'item.address_point_id_str', class: ' scrfull' }
                },
                    { 
                        header: { text: 'Адрес', style: '', class: 'text-center scr1024' }, 
                        item: { eval: 'item.address_point_id_str', 
                        class: 'text-center  scr1024' }
                    },
            ],

            id: '',

            is_new: true,

            date: '',

            shipping_date: '',

            contractor_id: '',
            delivery_address: '',

            posted: false,
            is_deleted: false,

            comment: '',

            sum: 0,

            items: []

        };
    },
    mounted() {

        settings.getUserSettings((err, data) => {

            if (err) {
                        this.error.visible = true;
                        this.error.message = data;
            }
            else {

                this.user = data.user

                this.user_settings = data.user_settings;

                this.table.accessFilter = [ this.table.name + '.user_id = @user_id' ]
                this.table.params = [ { name: 'user_id', type: 'Char', value: this.user.token } ]

                this.executeRequest('gettable', this.table, 'POST', (result) => {

                     this.items = result

                     let ln = 0
                     this.items.forEach(i => { 
                        i.ln = ln
                        ln = ln + 1
                      })

                     this.loading = false

                })
                        

            }
        })

    },
    methods: {

        onClickItem(){

        },


        onDblClickItem(select_item){

            this.$router.push('/orderrecord?ln=' + select_item.line_number)

        },

        getSettingsProducts(){

          const table = {
                name: tables.product_set_users.name,
                fields: tables.product_set_users.fields,
                accessFilter: ['user_id = @user_id'],
                params: [ { name: 'user_id', type: 'Char', value: this.user.token } ]
            }

          this.executeRequest('gettable', table, 'POST', result => {

            if (!result.length) {
                
                this.error.visible = true;
                this.error.message = 'user sets empty';
            } else {

                this.contractor_id = result[0].contractor_id
                this.delivery_address = result[0].address_points_address

                const product_table = {
                        name: tables.product_set_products.name,
                        fields: tables.product_set_products.fields,

                        joins: tables.product_set_products.joins,

                        accessFilter: ['doc_id = @doc_id'],
                        params: [ { name: 'doc_id', type: 'Char', value: result[0].doc_id } ],

                        top: 100,

                        order: ['line_number']
                    }

                this.executeRequest('gettable', product_table, 'POST', result => {

                    let line_number = 0

                    result.forEach(line => {
                        
                        this.products.push( { 
                            id: datestr.uuid4(), 
                            doc_id: this.id, 
                            line_number: line_number,
                            product_id: line.product_id, 
                            product_id_str: line.name,
                            price: Math.round(line.price / 100),
                            characteristic_id: datestr.uuid_nil(),
                            base_unit_name: line.base_unit_name,
                            unit_id_str: line.unit_id_str,
                            unit_price: Math.round(line.unit_price / 100),
                            coefficient: Math.round(line.coefficient / 1000),
                            quantity: 0,
                            sum: 0
                        } )

                        line_number = line_number + 1

                    });

                    const order_products = this.table.tables.filter(t => t.name == 'order_products')[0]

                    order_products.accessFilter = ['doc_id = \'' + this.id + '\'']

                    this.executeRequest('gettable', order_products, 'POST', (result) => {

                        result.forEach(r => {

                            const curLine = this.products.filter(p => p.product_id == r.product_id)[0]
                            
                            curLine.quantity = Math.round(r.quantity / 1000) * Math.round(r.coefficient / 1000) / curLine.coefficient
                            curLine.sum = curLine.quantity * curLine.unit_price

                        })

                        if (this.posted) {
                        
                            this.products = this.products.filter(p => p.quantity != 0)
                            
                        }

                        this.loading = false

                    })
                })
                    
            }

          })


        },

        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.error.visible = true;
                    this.error.message = result;
                }
                else {
                    callback(result);
                }
            });
        },

        saveOrder(){

            let curSum = 0
            this.products.forEach(p => {

                if (p.quantity > 0) {
                    curSum += p.quantity * p.price
                    p.quantity *= 1000
                }

            }) 

            this.table.record = {

                id: this.id,
                date: this.is_new ? datestr.dateToYMDHMS(new Date()) : this.date,
                is_deleted: this.is_deleted,
                contractor_id: this.contractor_id,
                sum: curSum * 100,
                comment: this.comment,
                author_id: this.user.token,
                posted: this.posted,
                delivery_address: this.delivery_address,

                shipping_date: this.shipping_date.length < 10 ? '' : this.shipping_date.substr(6, 4) + this.shipping_date.substr(3, 2) + this.shipping_date.substr(0, 2),

                order_products: this.products.filter(p => p.quantity > 0)

            }

            this.loading = true

            this.executeRequest(this.is_new ? 'insertrecord' : 'updaterecord', this.table, 'POST', (result) => {
            
                this.items = result

                this.table.record.sum = Math.round(this.table.record.sum / 100)

                this.table.record.order_products.forEach( op => { 
                    op.quantity = Math.round(op.quantity / 1000) * op.coefficient
                } )

                this.executeRequest('postuppupr', { setOrder: this.table.record }, 'POST', result => {

                    this.items = result

                    this.loading = false

                    this.$router.push('/orders')

                })

            })

        },

        onLineButtonClick(...arg){

            if (arg[0][1][0] == 'changeQuantity') {
            
                const curLine = this.products.filter(p => p.id == arg[0][0].id)[0]
                
                curLine.quantity += arg[0][1][1]

                if (curLine.quantity < 0) {
                    curLine.quantity = 0
                }
    
                curLine.sum = curLine.quantity * curLine.coefficient * curLine.price

                this.sum = 0
                
                this.products.forEach(p => {

                    this.sum += p.sum

                })

            }

        }

    },
    components: { NavigationBar, ErrorBar, LoaderCircle, ListItems }
}

</script>