<template>

    <tr class="border-b dark:border-neutral-500 dark:bg-neutral-700 hover:bg-neutral-100  dark:hover:bg-neutral-600"
     style="cursor: pointer;" v-bind:class="{ selected: item.selected, 'bg-white': index % 2 != 0, 'bg-neutral-200': index % 2 == 0 }" 
        v-on:click="onClickItem(item.id)"
        v-on:dblclick="onDblClickItem(item.id)">

        <td class="px-6 py-4" v-for="(field, findex) in fields" v-bind:key="findex" v-bind:style="field.item.style" v-bind:class="strEval(item, field.item.class)">
            <div v-if="field.item.html_pred" v-html="strEval(item, field.item.html_pred)" v-bind:style="field.item.html_pred_style"  v-on:click="onLineButtonClick(item, field.item.html_pred_click)" ></div>
            
            {{ strEval(item, field.item.eval) }}
            <div v-if="field.item.html" v-html="strEval(item, field.item.html)"></div>
            <i v-if="field.item.icon && strEval(item, field.item.icon.eval)" v-bind:style="field.item.icon.style" v-bind:class="field.item.icon.class">
                {{ field.item.icon.text }}</i>
            <div v-if="field.item.html_post" v-html="strEval(item, field.item.html_post)" v-bind:style="field.item.html_post_style"  v-on:click="onLineButtonClick(item, field.item.html_post_click)"></div>
        </td>
            
    </tr>

</template>

<script>

import datestr  from '@/datestr';

export default {

    props: {

        fields: { type: Array },
        item: {
            type: Object,
            required: true
        },

        index: Number
    },

    mounted() {

    },

    methods: {

        strToDateTime(sd) {

            return datestr.strToDateTime(sd)
        
        },

        strToDate(sd) {

            return datestr.strToDate(sd)
        
        },

        strEval(item, sd) {

            let res = ''

            try {
            
                res = eval(sd)
                
            } catch (error) {

                res = sd
                
            }

            return res
        
        },


        onClickItem(id) {

            this.$emit('on-click-item', id);

        },

        onDblClickItem(id) {

            this.$emit('on-dblclick-item', id);

        },

        onLineButtonClick(item, arg){
            
            this.$emit('on-line-button-click', item, arg);

        }




    }
}
</script>

<style scoped>

    input {

        margin-right: 1rem;

    }

    li {
        border: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        padding: .5rem 2rem;
        margin-bottom: 1rem;
    }

    .rm {
        background: red;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;

    }

    .done {

        text-decoration: line-through;

    }

    i.check {
        display: inline-block;
        width: 15px;
        height: 25px;
        margin: -3px 10px 3px;
        border: solid green;
        border-width: 0 5px 5px 0;
        transform: rotate( 45deg);
    }

    i.checkred {
        display: inline-block;
        width: 15px;
        height: 25px;
        margin: -3px 10px 3px;
        border: solid red;
        border-width: 0 5px 5px 0;
        transform: rotate( 45deg);
    }


    .tc:hover {
        
        background-color: rgba(36, 66, 165, 0.5);

      }

    .selected {
        
        background-color: #2196f3;
        color: white;

      }


      .selected:hover {
        
        background-color: rgb(162, 0, 255);

      }

    .scrfull {
    
        display: table-cell;
        
    }

    .scr1024 {
    
        display: none;
        
    }

    .scr1024flex {
    
        display: none;
        
    }

    @media screen and (max-width: 1024px) {

        .scrfull {
        
            display: none;
            
        }

        .scr1024 {
        
        display: block;
        
    }

        .scr1024flex {
        
            display: flex;
            
        }

    .py-4 {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        table tr {

            display: grid;

        }

    }


</style>