export default {

    

    addr: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'srv/',

    addr_email: 'https://ow.apx-service.ru:3000/',

    addr_bitrix: 'https://bitrix.apx-service.ru/rest/1/zazjzmzmridwx32j/'

}

