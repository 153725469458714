/* import axios from 'axios'
 */
import conn from '@/connections'

import { v4 as uuidv4, NIL as uuid_nil } from 'uuid'


/* function executeRequest(req, data, method, callback, callbackerr){

    axios({url: conn.addr + req, data: data, method: method })
    .then(resp => {

        const jsd = resp.data

        if(jsd.success){

          callback(jsd.result)

        } else {
        
            callbackerr(resp.data.message)

        }

    })
    .catch(err => {

        callbackerr(err)

    })



}

function execReq(req, data, method, callback){

    axios({url: conn.addr + req, data: data, method: method })
    .then(resp => {

        const jsd = resp.data

        if(jsd.success){

          callback(false, jsd.result)

        } else {
        
            callback(true, resp.data.message)

        }

    })
    .catch(err => {

        callback(true, err)

    })



}
 */
function fetchReq(req, data, method, callback){

    const url = conn.addr + req

    if (method == 'GET') {
        
        fetch(url, { method: method, headers: {
            "Content-Type": "application/json"
        } })
            .then(response => response.json())
            .then(json => {

                if (json.success) {
                    
                    callback(false, json.result ? json.result : json.responses )

                } else {

                    callback(true, json.message)

                }

            })
            .catch(error => {
                callback(true, error)
            })

    } else {
        
        fetch(url, { method: method, headers: {
            "Content-Type": "application/json"
        }, body: JSON.stringify(data) })
            .then(response => response.json())
            .then(json => {

                if (json.success) {
                    
                    callback(false, json.result)

                } else {

                    callback(true, json.message)

                }

            })
            .catch(error => {
                callback(true, error)
            })

    }



}

function fetchReqErr(req, data, method, err, callback){

    const url = conn.addr + req

    if (method == 'GET') {
        
        fetch(url, { method: method, headers: {
            "Content-Type": "application/json"
        } })
            .then(response => response.json())
            .then(json => {

                if (json.success) {
                    
                    callback(json.result ? json.result : json.responses )

                } else {

                    err.hidden = false
                    err.message = json.message

                    callback()

                }

            })
            .catch(error => {

                err.hidden = false
                err.message = error

                callback()
            })

    } else {
        
        fetch(url, { method: method, headers: {
            "Content-Type": "application/json"
        }, body: JSON.stringify(data) })
            .then(response => response.json())
            .then(json => {

                if (json.success) {
                    
                    callback(json.result)

                } else {

                    err.hidden = false
                    err.message = json.message

                    callback()

                }

            })
            .catch(error => {
                
                err.hidden = false
                err.message = error

                callback()
            })

    }



}

function fetchReqBitrix(req, data, method, callback){

    const url = conn.addr_bitrix + req

    if (method == 'GET') {
        
        fetch(url, { method: method })
            .then(response => response.json() )
            .then(json => {

                callback(false, json)

            })
            .catch(error => {
                callback(true, error)
            })

    } else {
        
        fetch(url, { method: method, headers: {
            "Content-Type": "application/json"
        }, body: JSON.stringify(data) })
            .then(response => response.json())
            .then(json => {

                callback(false, json.result)


            })
            .catch(error => {
                callback(true, error)
            })

    }



}

function history_join(tablename) {
    
    return {
        field: 'isnull(history_records.qv, 0) as number_of_history_records', 
        table: 'left join (select object_type, object_id, sum(1) as qv from object_history group by object_type, object_id) as history_records on history_records.object_type = \'' + tablename + '\' and history_records.object_id = ' + tablename + '.id' 
    }
    
}
    
function files_join(tablename) {
    
    return {
        field: 'isnull(file_owners.qf, 0) as number_of_files', 
        table: 'left join (select owner_name, owner_id, sum(1) as qf from file_owners where is_deleted = 0 group by owner_name, owner_id) as file_owners on file_owners.owner_name = \'' + tablename + '\' and file_owners.owner_id = ' + tablename + '.id' 
    }

}

function getObjectData(tableName, fields, params, error, callback){
      
    let accessFilter = []
    let curParams = typeof(params) == 'string' ? [ { name: 'id', value: params } ] : ( Array.isArray(params) ? params : [ params ] )
    curParams.forEach(p => {
      accessFilter.push( p.name + ' = @' + p.name )
      if (!p.type) {
        p.type = 'Char'
      }
    })

      const orgIBI = { 
        name: tableName, 
        fields: Array.isArray(fields) ? fields : fields.replaceAll(' ', '').split(','),
        accessFilter: accessFilter,
        params: curParams
      }

      fetchReq('gettable', orgIBI, 'POST', (err, result) => {

        if (err) {

            error.visible = true
            error.message = result
            
        } else {
            
            callback(result)

        }

      })

  }

function setObjectData(tableName, userId, record, error, callback){
    
    const curFields = []

    for (const key in record) {
        curFields.push(key)
    }

      const orgIBI = { 
        name: tableName, 
        record: record,
        fields: curFields
      }

      orgIBI.record.user_id = userId

      fetchReq('updaterecord', orgIBI, 'POST', (err, result) => {

        if (err) {

            error.visible = true
            error.message = result
            
        } else {
            
            callback(result)

        }

      })

  }

  function updateObjectData(table, error, callback){

      fetchReq('updaterecord', table, 'POST', (err, result) => {

        if (err) {

            error.visible = true
            error.message = result
            
        } else {
            
            callback(result)

        }

      })


  }
      
  function insertObjectData(table, error, callback){

      fetchReq('insertrecord', table, 'POST', (err, result) => {

        if (err) {

            error.visible = true
            error.message = result
            
        } else {
            
            callback(result)

        }

      })


  }

  function setObjectData1(table, mode, error, callback){

    if (mode) {
    
        updateObjectData(table, error, callback)
        
    } else {
        
        insertObjectData(table, error, callback)

    }


  }




export default {

    fetchReq,
    fetchReqErr,
    fetchReqBitrix,

    uuidv4,
    uuid_nil,

    history_join,
    files_join,

    getObjectData,
    updateObjectData,
    insertObjectData,
    setObjectData, setObjectData1




}

