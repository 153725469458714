
export const user_settings = {
    name: 'user_settings',
    fields: [ 'id', 'user_id', 'setting_id', 'value' ],
    joins: [

      {
        field: 'settings.name as name', 
        table: 'join settings on user_settings.setting_id = settings.id' 
      }

    ],
    accessFilter: []
}

const products = {

  name: 'products',
  fields: [ 'id', 'artikul', 'name', 'full_name', 'is_group', 'parent', 'is_deleted', 'comment', 'unit_id' ]

}

const start_of_measurements = {

    name: 'start_of_measurements',
    fields: [ 
      'id', 
      'date', 
      { name: 'number', autoinc: true }, 
      'is_deleted',
      'posted', 
      { name: 'author_id', type: 'ref', table: 'users', records: [] },
      'comment', 
      { name: 'product_id', type: 'ref', table: 'products', records: [] }, 
      'quantity', 
      { name: 'technology_operation_id', type: 'ref', table: 'technology_operations', records: [] }, 
      { name: 'project_id', type: 'ref', table: 'projects', records: [] }, 
      { name: 'department_id', type: 'ref', table: 'departments', records: [] }, 
      'man_quantity'
    ],
    joins: [

      {
        field: [
          'cast( case when end_of_measurements.id is null then 0 else 1 end as bit) as ended', 
          'case when end_of_measurements.id is null then \'\' else end_of_measurements.date end as end_date'
        ],
        table: 'left join end_of_measurements on start_of_measurements.id = end_of_measurements.start_of_measurement_id' 
      },

      {
        field: [
          'cast( case when pause_of_measurements.start_of_measurement_id is null then 0 else 1 end as bit) as paused', 
          'case when pause_of_measurements.start_of_measurement_id is null then \'\' else pause_of_measurements.date end as pause_date'
        ],
        table: 'left join (select start_of_measurement_id, max(date) as date from pause_of_measurements group by start_of_measurement_id) as pause_of_measurements on start_of_measurements.id = pause_of_measurements.start_of_measurement_id' 
      },

      {
        field: [
          'cast( case when continue_of_measurements.start_of_measurement_id is null then 0 else 1 end as bit) as continued', 
          'case when continue_of_measurements.start_of_measurement_id is null then \'\' else continue_of_measurements.date end as continue_date'
        ],
        table: 'left join (select start_of_measurement_id, max(date) as date from continue_of_measurements group by start_of_measurement_id) as continue_of_measurements on start_of_measurements.id = continue_of_measurements.start_of_measurement_id' 
      },

    ],
    accessFilter: [],


}

const end_of_measurements = {

    name: 'end_of_measurements',
    fields: [ 
      'id', 
      'date', 
      { name: 'number', autoinc: true }, 
      'is_deleted',
      'posted', 
      { name: 'author_id', type: 'ref', table: 'users', records: [] },
      'comment', 
      { name: 'start_of_measurement_id', type: 'ref', table: 'start_of_measurements', records: [] }
    ],
    joins: [

    ],
    accessFilter: [],


}

const pause_of_measurements = {

    name: 'pause_of_measurements',
    fields: [ 
      'id', 
      'date', 
      { name: 'number', autoinc: true }, 
      'is_deleted',
      'posted', 
      { name: 'author_id', type: 'ref', table: 'users', records: [] },
      'comment', 
      { name: 'start_of_measurement_id', type: 'ref', table: 'start_of_measurements', records: [] }
    ],
    joins: [

    ],
    accessFilter: [],


}

const continue_of_measurements = {

    name: 'continue_of_measurements',
    fields: [ 
      'id', 
      'date', 
      { name: 'number', autoinc: true }, 
      'is_deleted',
      'posted', 
      { name: 'author_id', type: 'ref', table: 'users', records: [] },
      'comment', 
      { name: 'start_of_measurement_id', type: 'ref', table: 'start_of_measurements', records: [] }
    ],
    joins: [

    ],
    accessFilter: [],


}


const orders = {

    name: 'orders',
    fields: [ 
      'id', 
      'date', 
      { name: 'number', autoinc: true }, 
      'is_deleted', 
      { name: 'contractor_id', type: 'ref', table: 'contractors', records: [] }, 
      'sum', 
      'comment', 
      'posted', 
      { name: 'author_id', type: 'ref', table: 'users', records: [] },
      'delivery_address', 'shipping_date'
    ],
    joins: [


    ],
    accessFilter: [],

    tables: [
      { 
        name: 'order_products',
        key: 'doc_id',
        fields: [
            'id', 
            'doc_id', 
            'line_number',
            { name: 'product_id', type: 'ref', table: 'products', records: [] },
            'characteristic_id', 
            'quantity',
            'coefficient'
        ],
        order: [ 'line_number' ]

      
      }
    ]

}

const product_set_products = { 

  name: 'product_set_products',
  key: 'doc_id',
  fields: [
      'id', 
      'doc_id', 
      'line_number',
      { name: 'product_id', type: 'ref', table: 'products', records: [] },
      'name', 
      'price',
      { name: 'unit_id', type: 'ref', table: 'units', records: [] },
      'unit_price'
  ],

  joins: [

    {
      field: 'pu.base_unit_name as base_unit_name', 
      table: 'left join (select products.id, units.name as base_unit_name from products join units on units.id = products.unit_id) as pu on pu.id = product_id' 
    },

    {
      field: 'units.coefficient as coefficient', 
      table: 'left join units on units.id = product_set_products.unit_id' 
    }
  ],

  order: [ 'line_number' ]
}

const product_set_users = { 

  name: 'product_set_users',
  key: 'doc_id',
  fields: [
      'id', 
      'doc_id', 
      'line_number',
      { name: 'user_id', type: 'ref', table: 'users', records: [] },
      { name: 'contractor_id', type: 'ref', table: 'contractors', records: [] },
      { name: 'address_point_id', type: 'ref', table: 'address_points', fields: ['address'], records: [] }
  ],
  order: [ 'line_number' ]

}

const product_sets = {

    name: 'product_sets',
    fields: [ 
      'id', 
      'name', 
      'is_deleted', 
      'comment', 
      'min_sum'
    ],
    joins: [


    ],
    accessFilter: [],

    tables: [
      product_set_users,

      product_set_products,
      
    ]

}

const technology_operations = {

    name: 'technology_operations',
    fields: [ 
      'id', 
      'name', 
      'is_deleted'
    ],
    joins: [


    ],
    accessFilter: [],

}

const projects = {

    name: 'projects',
    fields: [ 
      'id', 
      'name', 
      'is_deleted'
    ],
    joins: [


    ],
    accessFilter: [],

}

const departments = {

    name: 'departments',
    fields: [ 
      'id', 
      'name', 
      'is_deleted'
    ],
    joins: [


    ],
    accessFilter: [],

}

export default {

    user_settings, 
    
    start_of_measurements, end_of_measurements, pause_of_measurements, continue_of_measurements,

    orders, 
    
    products,

    product_sets, product_set_users, product_set_products,

    technology_operations, projects, departments

}