<template>

    <nav
    class="flex-no-wrap relative flex w-full items-center justify-between bg-neutral-100 py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4"
    data-te-navbar-ref>
    <div class="flex w-full flex-wrap items-center justify-between px-3">
        <!-- Hamburger button for mobile view -->
        <button
        class="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
        type="button"
        data-te-collapse-init
        data-te-target="#navbarSupportedContent1"
        aria-controls="navbarSupportedContent1"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <!-- Hamburger icon -->
        <span class="[&>svg]:w-7">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-7 w-7">
            <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clip-rule="evenodd" />
            </svg>
        </span>
        </button>

        <!-- Collapsible navigation container -->
        <div
        class="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
        id="navbarSupportedContent1"
        data-te-collapse-item>
        <!-- Logo -->
        <a
            class="hidden mb-4 mr-2 mt-3 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-0 lg:mt-0"
            href="#">
            <img
            src="https://tecdn.b-cdn.net/img/logo/te-transparent-noshadows.webp"
            style="height: 15px"
            alt=""
            loading="lazy" />
        </a>
        <!-- Left navigation links -->
        <ul
            class="list-style-none mr-auto flex flex-col pl-0 lg:flex-row"
            data-te-navbar-nav-ref>
            <li v-for="leftItem in settings.left.concat(settings.selected || [] )" v-bind:key="leftItem.name" class="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
            <!-- Dashboard link -->
            <a
                class="flex items-center whitespace-nowrap py-2 pr-2  hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400"
                href="#"
                data-te-nav-link-ref
                v-on:click.prevent="strEval(leftItem.click)"
                >
                <div v-if="leftItem.icon" v-html="leftItem.icon"></div>
                {{ leftItem.name }}</a
            >
            </li>

        </ul>
        </div>

        <!-- Right elements -->
        <div class="relative flex items-center">

            <a v-for="leftItem in settings.right" v-bind:key="leftItem.name" 
                class="flex items-center whitespace-nowrap py-2 pr-2  hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400"
                href="#"
                data-te-nav-link-ref
                v-on:click.prevent="strEval(leftItem.click)"
                >
                <div v-if="leftItem.icon" v-html="leftItem.icon"></div>
                {{ leftItem.name }}</a
            >

        </div>
    </div>
    </nav>

</template>

<script>

import srvreq from '@/srvreq.js'
        import {
  Collapse,
  Dropdown,
  initTE,
} from "tw-elements";


export default {
    
    props:{

        settings: Object

    },

    data() {
        return {
            user: {},
            user_settings: [],
            is_error: false,
            error_message: ""
        };
    },
    mounted() {

        initTE({ Collapse, Dropdown });


        // Initialization for ES Users
        this.user = JSON.parse(localStorage.getItem('user'));
        const table = {
            name: 'user_settings',
            fields: [ 'id', 'user_id', 'setting_id', 'value' ],
            joins: [

              {
                field: 'settings.name as name', 
                table: 'join settings on user_settings.setting_id = settings.id' 
              }

            ],
            accessFilter: ["user_id = '" + this.user.token + "'"]
        };
        this.executeRequest('gettable', table, 'POST', result => {
            this.user_settings = result;
        });
    },
    methods: {

        strEval(sd) {

            return eval(sd)
        
        },

        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.is_error = true;
                    this.error_message = result;
                }
                else {
                    callback(result);
                }
            });
        },
        Logout() {
            localStorage.clear();
            this.$router.push("/login");
        }



    }

}
</script>

