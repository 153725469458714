<template>

    <header>

        <NavigationBar
            v-bind:settings="nav_bar_settings"
            v-on:on-click-stop-measurement="stopMeasurement()"
            v-on:on-click-start-measurement="startMeasurement()"
            v-on:on-click-pause-measurement="pauseMeasurement()"
            v-on:on-click-continue-measurement="continueMeasurement()"
        />


    </header>



    <ErrorBar
            v-bind:is_error="error.visible"
            v-bind:error_message="error.message"
            v-on:remove-error="error.visible = false"      

    />


    <div v-if="loading">
            <LoaderCircle />
    </div>
    <div v-else-if="items.length">

        <ListItems 
            v-bind:fields="fields" 
            v-bind:items="items" 
            v-on:on-click-item="onClickItem"
            v-on:on-dblclick-item="onDblClickItem"
        />

    </div>
    <p v-else>нет измерений</p>

</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'
import datestr from '@/datestr';

import ListItems from '@/components/list/ListItems.vue';
import LoaderCircle from '@/components/LoaderCircle.vue';

import tables from '@/tables';

export default {

  components: { NavigationBar, ErrorBar, LoaderCircle, ListItems },


    data() {
        return {
            user: {},
            user_settings: [],
            error: { visible: false, message: '' },
            nav_bar_settings: {
              left: [ navigation.home ],
              selected: [],
              right: [ 
                navigation.logout
                ]              
            },

            loading: true,

            table: tables.start_of_measurements,

            fields: [
                { 
                    header: { text: 'Дата', style: 'width: 10%;', class: 'text-center'}, 
                    item: { 
                        eval: 'this.strToDateTime(item.date) + " № " + item.number', class: 'whitespace-nowrap text-center',
                        html_pred: 'item.posted ? \'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="inline-block w-6 h-6">'
                            + '<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />'
                            + '</svg>\' : ( item.is_deleted ?  \'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="inline-block w-6 h-6">'
                            + '<path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />'
                            + '</svg>\'  : \'\')',
                            html_pred_style: "display: inline-block" 
                } 
                },
                { 
                    header: { text: 'Номенклатура', class: 'text-center' }, 
                    item: { eval: 'item.product_id_str', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Количество', class: 'text-center' }, 
                    item: { eval: 'Math.round(item.quantity / 1000).toLocaleString(`ru`, { minimumFractionDigits: 2 })', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Тех.операция', class: 'text-center' }, 
                    item: { eval: 'item.technology_operation_id_str', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Проект', class: 'text-center' }, 
                    item: { eval: 'item.project_id_str', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Цех', class: 'text-center' }, 
                    item: { eval: 'item.department_id_str', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Кол-во человек', class: 'text-center' }, 
                    item: { eval: 'Math.round(item.man_quantity / 1000).toLocaleString(`ru`, { minimumFractionDigits: 2 })', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Автор', class: 'text-center' }, 
                    item: { eval: 'item.author_id_str', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Комментарий', class: 'text-center' }, 
                    item: { eval: 'item.comment', class: 'text-center whitespace-break-spaces' }
                },
                { 
                    header: { text: 'Статус', class: 'text-center' }, 
                    item: { eval: 'item.ended ? \'Завершен \' + this.strToDateTime(item.end_date) : '
                        + '(item.posted ? (item.continue_date > item.pause_date ? \'Продолжено с \' + this.strToDateTime(item.continue_date) : '
                        + '(item.pause_date != \'\' ? \'Остановлено с \' + this.strToDateTime(item.pause_date) : \'Измеряется\')) : \'\')', class: 'text-center whitespace-break-spaces' }
                }
            ],

            items: []






        };
    },
    mounted() {

      settings.getUserSettings((err, data) => {

        if (err) {
                    this.error.visible = true;
                    this.error.message = data;
        }
        else {
          this.user = data.user

          this.user_settings = data.user_settings;

          if (settings.getSettingEnabled(this.user_settings, 'admining')
            || settings.getSettingEnabled(this.user_settings, 'edit_measurements')) {

            this.nav_bar_settings.left.push( navigation.add_measurement )
            
          }

          this.table.top = 10
          this.table.order = ['date desc']
          this.table.accessFilter = []

          if (!settings.getSettingEnabled(this.user_settings, 'admining')) {

            this.table.accessFilter = [tables.start_of_measurements.name +  '.author_id = \'' + this.user.token + '\'']

          }

          this.getItems()

        }
      })

    },
    methods: {
        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.error.visible = true;
                    this.error.message = result;
                }
                else {
                    callback(result);
                }
            });
        },

        getItems(){

            this.executeRequest('gettable', this.table, 'POST', (result) => {
            
            this.items = result

            this.loading = false

            })

        },

        onClickItem(){

            let selectedItem = this.items.filter(i => i.selected)[0]

            this.nav_bar_settings.selected = []
            if (!selectedItem.posted) {
                
                this.nav_bar_settings.selected.push(navigation.start)
                
            } else if (!selectedItem.ended) {
                    
                if (selectedItem.continue_date >= selectedItem.pause_date) {
                    
                    this.nav_bar_settings.selected.push(navigation.pause)
                    
                } else {

                    this.nav_bar_settings.selected.push(navigation.nContinue)
                    
                }

                this.nav_bar_settings.selected.push(navigation.stop)

            }
        },

        onDblClickItem(order){

            this.$router.push('/measurementrecord?id=' + order.id)

        },
        
        stopMeasurement(){

            const eomTable = {
                name: tables.end_of_measurements.name,
                fields: tables.end_of_measurements.fields,
                record: {

                    id: datestr.uuid4(),
                    date: datestr.dateToYMDHMS(new Date()),
                    is_deleted: false,
                    posted: true,
                    author_id: this.user.token,
                    comment: '',
                    start_of_measurement_id: this.items.filter(i => i.selected)[0].id

                }
            }

            this.loading = true

            this.executeRequest('insertrecord', eomTable, 'POST', () => {
            
                this.nav_bar_settings.selected = []

                 this.executeRequest('postuppupr', { setEndMeasurement: eomTable.record }, 'POST', () => {

                    this.loading = false

                    this.getItems()

                })
 
            })

        },

        startMeasurement(){

            this.loading = true

            let selectedItem = this.items.filter(i => i.selected)[0]

            let curRecord = { id: selectedItem.id, posted: true }

            srvreq.setObjectData(tables.start_of_measurements.name, this.user.token, curRecord, this.error, () => {

                this.nav_bar_settings.selected = []

                 this.executeRequest('postuppupr', { setStartMeasurement: curRecord }, 'POST', () => {

                    this.loading = false

                    this.getItems()

                })
 
            })

        },

        pauseMeasurement(){

            const eomTable = {
                name: tables.pause_of_measurements.name,
                fields: tables.pause_of_measurements.fields,
                record: {

                    id: datestr.uuid4(),
                    date: datestr.dateToYMDHMS(new Date()),
                    is_deleted: false,
                    posted: true,
                    author_id: this.user.token,
                    comment: '',
                    start_of_measurement_id: this.items.filter(i => i.selected)[0].id

                }
            }

            this.loading = true

            this.executeRequest('insertrecord', eomTable, 'POST', () => {
            
                this.nav_bar_settings.selected = []

                 this.executeRequest('postuppupr', { setPauseMeasurement: eomTable.record }, 'POST', () => {

                    this.loading = false

                    this.getItems()

                })
 
            })

        },

        continueMeasurement(){

            const eomTable = {
                name: tables.continue_of_measurements.name,
                fields: tables.continue_of_measurements.fields,
                record: {

                    id: datestr.uuid4(),
                    date: datestr.dateToYMDHMS(new Date()),
                    is_deleted: false,
                    posted: true,
                    author_id: this.user.token,
                    comment: '',
                    start_of_measurement_id: this.items.filter(i => i.selected)[0].id

                }
            }

            this.loading = true

            this.executeRequest('insertrecord', eomTable, 'POST', () => {
            
                this.nav_bar_settings.selected = []

                 this.executeRequest('postuppupr', { setContinueMeasurement: eomTable.record }, 'POST', () => {

                    this.loading = false

                    this.getItems()

                })
 
            })

        },

    },
}

</script>