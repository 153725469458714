<template>
    <div v-bind:class="{ hidden: !is_error }" style="z-index: 1000; position: absolute; width: 100%; height: 45px; top: 0; justify-content: space-between;">

        <div
        class="mb-3 hidden w-full items-center rounded-lg bg-danger-600 px-6 py-5 text-base text-white data-[te-alert-show]:inline-flex"
        role="alert"
        data-te-alert-init
        data-te-alert-show>
        <strong class="mr-1">Ошибка! </strong> {{ error_message }}
        <button
            type="button"
            class="ml-auto box-content rounded-none border-none p-1 text-white opacity-50 hover:text-warning-900 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-alert-dismiss
            v-on:click="$emit('remove-error')"
            aria-label="Close">
            <span
            class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="h-6 w-6">
                <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clip-rule="evenodd" />
            </svg>
            </span>
        </button>
        </div>
    </div>

</template>

<script>

    // Initialization for ES Users
/*     import {
    Alert,
    initTE,
    } from "tw-elements";

 */
export default {

    props: {
        is_error: {
            type: Boolean,
            default: false
        }, 
        error_message: {
            type: String,
            default: ''
        }
    },

    mounted() {

        //initTE({ Alert });


    }

}



</script>