<template>

    <div v-for="row in formSettings.rows" class="row" v-bind:key="row">
        <div style="position: relative;" v-for="inputField in row.inputs" v-bind:class="inputField.class" v-bind:key="inputField" v-bind:style="inputField.style">
            <p v-if="inputField.text">{{ inputField.text }}</p>

            <label v-if="inputField.type == 'checkbox'">
                <input type="checkbox" v-model="record[inputField.id]" />
                <span>{{ inputField.label }}</span>
            </label>                                

            <label v-if="inputField.type != 'checkbox' && inputField.label" v-bind:for="inputField.id" v-bind:class="{ active: record[inputField.id] }">{{ inputField.label }}</label>
            <input v-if="inputField.type != 'checkbox' && inputField.id" v-bind:id="inputField.id" v-model="record[inputField.id]" v-on:keyup="inputField.onKeyUp($event)"
                v-bind:type="inputField.type" class="validate" v-bind:readonly="inputField.readonly" v-bind:autocomplete="inputField.autocomplete">

            <ul v-if="inputField.type != 'checkbox' && inputField.list" v-bind:id="inputField.list.id" class="dropdown-content" 
                style="display: block; position: absolute; width: 70vw; max-height: 50vh; left: 10px; top: 80px; transform-origin: 0px 0px; opacity: 1; transform: scaleX(1) scaleY(1);">
                <li class="select_item" v-for="(item, index) in formSettings[inputField.list.id]" v-on:click="inputField.list.onClick(item)" v-bind:key="index">
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
    </div>




</template>

<script>

export default {

    data(){
        return {

            formSettings: {},

            record: {}

        }
    },

    methods: {

        init(formSettings, record){

            this.record = record

            this.formSettings = formSettings

        }

    }


}

</script>

<style scoped>

.row {

    padding-top: 6px;
    display: flex;

}

label {
    display: block;
}
input {

    border-radius: 6px;
    padding: 6px;
    border-color: #000;
    border: solid 1px;
    width: 100%;

}

ul {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #000;
    z-index: 1;
    overflow-y: auto;
}



li {

    text-align: left;

    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;

}

li:hover {

    background-color: rgb(243, 244, 246);

}

</style>