<template>

    <header>

        <NavigationBar
            v-bind:settings="nav_bar_settings"
        />


    </header>



    <ErrorBar
            v-bind:is_error="error.visible"
            v-bind:error_message="error.message"
            v-on:remove-error="error.visible = false"      

    />


    <div v-if="loading">
            <LoaderCircle />
    </div>
    <div v-else-if="items.length">

        <ListItems 
            v-bind:fields="fields" 
            v-bind:items="items" 
            v-on:on-click-item="onClickItem"
            v-on:on-dblclick-item="onDblClickItem"
        />

    </div>
    <p v-else>нет заказов</p>

</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'

import ListItems from '@/components/list/ListItems.vue';
import LoaderCircle from '@/components/LoaderCircle.vue';

import tables from '@/tables';

export default {

  components: { NavigationBar, ErrorBar, LoaderCircle, ListItems },


    data() {
        return {
            user: {},
            user_settings: [],
            error: { 
                visible: false,
                message: ""
            },
            nav_bar_settings: {
              left: [ navigation.home ],
              right: [ 
                navigation.logout
                ]              
            },

            loading: true,

            table: tables.orders,

            fields: [
                { 
                    header: { text: 'Дата', style: 'width: 10%;', class: 'text-center'}, 
                    item: { 
                        eval: 'this.strToDateTime(item.date) + " № " + item.number', class: 'whitespace-nowrap text-center',
                        html_pred: 'item.posted ? \'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="inline-block w-6 h-6">'
                            + '<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />'
                            + '</svg>\' : ( item.is_deleted ?  \'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="inline-block w-6 h-6">'
                            + '<path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />'
                            + '</svg>\'  : \'\')',
                            html_pred_style: "display: inline-block" 
                } 
                },
                { 
                    header: { text: 'Контрагент', class: 'text-center ' }, 
                    item: { eval: 'item.contractor_id_str', class: 'text-center whitespace-break-spaces  ' }
                },
                { 
                    header: { text: 'Адрес', class: 'text-center ' }, 
                    item: { eval: 'item.delivery_address', class: 'text-center whitespace-break-spaces  ' }
                },
                    { 
                        header: { text: 'Сумма', class: 'text-center py-1 scr1024' }, 
                        item: { eval: 'Math.round(item.sum / 100).toLocaleString(`ru`, { minimumFractionDigits: 2 }) + \', отгрузка \' + this.strToDate(item.shipping_date)', 
                        class: 'text-center whitespace-break-spaces py-1 scr1024' }
                    },
                { 
                    header: { text: 'Сумма', class: 'text-center scrfull' }, 
                    item: { eval: 'Math.round(item.sum / 100).toLocaleString(`ru`, { minimumFractionDigits: 2 })', class: 'text-center whitespace-break-spaces scrfull' }
                },
                { 
                    header: { text: 'Дата отгрузки', class: 'text-center scrfull' }, 
                    item: { eval: 'this.strToDate(item.shipping_date)', class: 'text-center whitespace-break-spaces scrfull' }
                },
                    { 
                        header: { text: 'Автор', class: 'text-center scr1024' }, 
                        item: { eval: 'item.author_id_str + (item.comment ? \', \' + item.comment : \'\')', class: 'text-center whitespace-break-spaces scr1024' }
                    },
                { 
                    header: { text: 'Автор', class: 'text-center scrfull' }, 
                    item: { eval: 'item.author_id_str', class: 'text-center whitespace-break-spaces scrfull' }
                },
                { 
                    header: { text: 'Комментарий', class: 'text-center scrfull' }, 
                    item: { eval: 'item.comment', class: 'text-center whitespace-break-spaces scrfull' }
                }
            ],

            items: []






        };
    },
    mounted() {

      settings.getUserSettings((err, data) => {

        if (err) {
                    this.error.visible = true;
                    this.error.message = data;
        }
        else {
          this.user = data.user

          this.user_settings = data.user_settings;

          this.table.top = 10
          this.table.order = ['date desc']
          this.table.accessFilter = []

          if (!settings.getSettingEnabled(this.user_settings, 'admining')) {

            this.table.accessFilter = ['author_id = \'' + this.user.token + '\'']

          }

          if (settings.getSettingEnabled(this.user_settings, 'admining')
            || settings.getSettingEnabled(this.user_settings, 'edit_orders')) {

            const params = [ 
                { name: 'user_id', value: this.user.token }, 
            ]

            srvreq.getObjectData(tables.product_set_users.name, 'contractor_id, address_point_id', params, this.error, result => {

                if (result.length == 1) {
                    
                    this.nav_bar_settings.left.push( navigation.add_order )

                } else {
                    this.nav_bar_settings.left.push( navigation.select_address )
                }

                this.getItems()
                
            })
            
          } else {

            this.getItems()
          }


        }
      })

    },
    methods: {
        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.error.visible = true;
                    this.error.message = result;
                }
                else {
                    callback(result);
                }
            });
        },

        getItems(){

            this.executeRequest('gettable', this.table, 'POST', (result) => {
            
            this.items = result

            this.loading = false

            })

        },

        onClickItem(){

        },

        onDblClickItem(order){

            this.$router.push('/orderrecord?id=' + order.id)

        }

    },
}

</script>