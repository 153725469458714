<template>

<header>

<NavigationBar
    v-bind:settings="nav_bar_settings"
    v-on:on-click-save-order="saveOrder()"
/>


</header>



<ErrorBar
    v-bind:is_error="!error.hidden"
    v-bind:error_message="error.message"
    v-on:remove-error="error.hidden = true"      

/>

<div v-if="loading">
            <LoaderCircle />
</div>
<div v-else >

<InputFieldsView ref="InputFieldsView"
/>

    <div v-if="false" class="flex justify-between">
        <h6 class="text-left my-3 mx-2" ><strong>Адрес доставки:</strong> {{ delivery_address }}</h6>

        <div id="datepicker-translated"
    class="relative my-3"
    data-te-datepicker-init
    data-te-format="dd.mm.yyyy"
    data-te-input-wrapper-init>
    <input
    v-model="shipping_date"
    data-te-input-state-active
        type="text"
        class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
        placeholder="Дата отгрузки" />
    <label
        for="floatingInput"
        class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
        >Дата отгрузки</label
    >
    </div>

        <h6 class="text-left my-3 mx-2" ><strong>Сумма:</strong> {{ sum.toLocaleString('ru', { minimumFractionDigits: 2 }) }}</h6>

    </div>

    <div class="relative mb-3 mx-2" data-te-input-wrapper-init>
    <textarea
        class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
        id="comment"
        rows="4"
        v-model="comment"
        placeholder="Комментарий" data-te-input-state-active data-te-input-focused></textarea>
        
    <label
        for="comment"
        class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
        >Комментарий
    </label>

    </div>

    <button
            type="button"
            class="inline-block rounded bg-success px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-ripple-init
            data-te-ripple-color="light"
            v-on:click.prevent="saveOrder(true)">
            Начать замер выработки
        </button>

    </div>


<div v-if="false">

    <div v-if="loading">
            <LoaderCircle />
    </div>
    <div v-else-if="products.length">

        <ListItems 
            v-bind:fields="fields" 
            v-bind:items="products" 
            v-on:on-click-item="onClickItem"
            v-on:on-dblclick-item="onDblClickItem"
            v-on:on-line-button-click="onLineButtonClick"
        />

    </div>
    <p v-else>нет товаров</p>
</div>


<select v-if="false">
    <option disabled>Выберите героя</option>
    <option value="Чебурашка">Чебурашка</option>
    <option selected value="Крокодил Гена">Крокодил Гена</option>
    <option value="Шапокляк">Шапокляк</option>
    <option value="Крыса Лариса">Крыса Лариса</option>
</select>


</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'
import LoaderCircle from '@/components/LoaderCircle.vue';
import ListItems from '@/components/list/ListItems.vue';
import datestr from '@/datestr';

import tables from '@/tables';

import {
    Datepicker,
  Input,
  initTE,
} from "tw-elements"
import InputFieldsView from '@/components/list/InputFieldsView.vue';


export default {
    data() {
        return {
            user: {},
            user_settings: [],
            error: { hidden: true, message: '' },
            nav_bar_settings: {
              left: [ navigation.home, navigation.measurements ],
              right: [ 
                navigation.logout
                ]              
            },

            table: tables.start_of_measurements,

            loading: false,

            fields: [
                { 
                    header: { text: '№', style: 'width: 2%;', class: 'text-center'}, 
                    item: { eval: 'item.line_number + 1', class: 'text-center'} 
                },
                { 
                    header: { text: 'Номенклатура', style: '', class: 'text-center' }, 
                    item: { eval: 'item.product_id_str + \' ( \' + item.base_unit_name + \' )\'', class: '\'font-bold\': item.quantity > 0\'' }
                },
                { 
                    header: { text: 'Цена за шт', style: '', class: 'text-center' }, 
                    item: { eval: 'item.price', class: 'text-center' }
                },
                { 
                    header: { text: 'Упак', style: '', class: 'text-center' }, 
                    item: { eval: 'item.unit_id_str + \' ( \' + item.coefficient + \' \' + item.base_unit_name + \' )\'', class: 'text-center' }
                },
                { 
                    header: { text: 'Цена за упак', style: '', class: 'text-center' }, 
                    item: { eval: 'item.unit_price', class: 'text-center' }
                },
               { 
                    header: { text: '', style: 'width: 2%;', class: 'text-center' }, 
                    item: {
                        html_pred: '\'<button type="button" '
                            + 'class="inline-block rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            +'-</button>\'',
                        html_pred_click: ['changeQuantity', -1] 
                    }
                },
               { 
                    header: { text: 'Количество', style: 'width: 2%;', class: 'text-center' }, 
                    item: {
                        eval: 'item.quantity + \' (\' + item.unit_id_str + \') \' + item.quantity * item.coefficient + \' (\' + item.base_unit_name + \') \'', class: 'text-center', 
                    }
                },
               { 
                    header: { text: '', style: 'width: 2%;', class: 'text-center' }, 
                    item: {
                        html_post: '\'<button type="button" '
                            + 'class="inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            +'+</button>\'', 
                        html_post_click: ['changeQuantity', 1] 
                    }
                },
            ],

            id: '',

            is_new: true,

            date: '',

            shipping_date: '',

            contractor_id: '',
            delivery_address: '',

            posted: false,
            is_deleted: false,

            comment: '',

            sum: 0,

            products: [],

            record: {
                product_id: '',
                product_id_str: ''
            },

            formSettings: {}

        };
    },
    mounted() {

        initTE({ Datepicker, Input })

/*         this.datepickerTranslated = new Datepicker(
        document.querySelector("#datepicker-translated"),
        {
            title: "Введите дату",
            monthsFull: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
            ],
            monthsShort: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
            ],
            weekdaysFull: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
            ],
            weekdaysShort: ["Вос", "Пон", "Вто", "Сре", "Чет", "Пят", "Суб"],
            weekdaysNarrow: ["В", "П", "В", "С", "Ч", "П", "С"],
            okBtnText: "Ок",
            clearBtnText: "Очистить",
            cancelBtnText: "Отмена",
        } 
        );
 */

        const in_id = this.$route.query.id

        this.id = in_id ? in_id : datestr.uuid4()
        this.is_new = in_id ? false : true

        this.formSettings = this.getFormSettings()

        this.$refs.InputFieldsView.init(this.formSettings, this.record)

        settings.getUserSettings((err, data) => {

            if (err) {
                        this.is_error = true;
                        this.error_message = data;
            }
            else {

                this.user = data.user

                this.user_settings = data.user_settings;

                if (this.is_new) {

                    this.shipping_date = '' 

                    this.getSettingsProducts(false)
                    
                } else {
                    
                    this.table.accessFilter = ['orders.id = \'' + this.id + '\'']

                    this.executeRequest('gettable', this.table, 'POST', (result) => {

                        this.date = result[0].date
                        this.contractor_id = result[0].contractor_id
                        this.delivery_address = result[0].delivery_address

                        this.sum = Math.round(result[0].sum / 100)

                        this.posted = result[0].posted
                        this.is_deleted = result[0].is_deleted
                        this.comment = result[0].comment

                        this.shipping_date = result[0].shipping_date === '        ' ? '' : result[0].shipping_date.substr(6, 2) + '.' + result[0].shipping_date.substr(4, 2) + '.' + result[0].shipping_date.substr(0, 4) 

                        this.getSettingsProducts()

                    })
                        
                }


            }
        })

    },
    methods: {

        getSettingsProducts(){

          if (!this.posted && settings.getSettingEnabled(this.user_settings, 'edit_measurements')) {

            this.nav_bar_settings.left.push( navigation.save_order )
            
          }

          const table = {
                name: tables.product_set_users.name,
                fields: tables.product_set_users.fields,
                accessFilter: ['user_id = @user_id'],
                params: [ { name: 'user_id', type: 'Char', value: this.user.token } ]
            }

          this.executeRequest('gettable', table, 'POST', result => {

            if (!result.length) {
                
/*                 this.is_error = true;
                this.error_message = 'user sets empty';
 */            } else {

                this.contractor_id = result[0].contractor_id
                this.delivery_address = result[0].address_points_address

                const product_table = {
                        name: tables.product_set_products.name,
                        fields: tables.product_set_products.fields,

                        joins: tables.product_set_products.joins,

                        accessFilter: ['doc_id = @doc_id'],
                        params: [ { name: 'doc_id', type: 'Char', value: result[0].doc_id } ],

                        top: 100,

                        order: ['line_number']
                    }

                this.executeRequest('gettable', product_table, 'POST', result => {

                    let line_number = 0

                    result.forEach(line => {
                        
                        this.products.push( { 
                            id: datestr.uuid4(), 
                            doc_id: this.id, 
                            line_number: line_number,
                            product_id: line.product_id, 
                            product_id_str: line.name,
                            price: Math.round(line.price / 100),
                            characteristic_id: datestr.uuid_nil(),
                            base_unit_name: line.base_unit_name,
                            unit_id_str: line.unit_id_str,
                            unit_price: Math.round(line.unit_price / 100),
                            coefficient: Math.round(line.coefficient / 1000),
                            quantity: 0,
                            sum: 0
                        } )

                        line_number = line_number + 1

                    });

                    const order_products = this.table.tables.filter(t => t.name == 'order_products')[0]

                    order_products.accessFilter = ['doc_id = \'' + this.id + '\'']

                    this.executeRequest('gettable', order_products, 'POST', (result) => {

                        result.forEach(r => {

                            const curLine = this.products.filter(p => p.product_id == r.product_id)[0]
                            
                            curLine.quantity = Math.round(r.quantity / 1000) * Math.round(r.coefficient / 1000) / curLine.coefficient

                        })

                        if (this.posted) {
                        
                            this.products = this.products.filter(p => p.quantity != 0)
                            
                        }

                        this.loading = false

                    })
                })
                    
            }

          })


        },

        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.is_error = true;
                    this.error_message = result;
                }
                else {
                    callback(result);
                }
            });
        },

        saveOrder(posted){

            this.table.record = {

                id: this.id,
                date: this.is_new ? datestr.dateToYMDHMS(new Date()) : this.date,
                is_deleted: this.is_deleted,
                posted: posted || false,
                author_id: this.user.token,
                comment: this.comment,
                product_id: this.record.product_id || datestr.uuid_nil(),
                quantity: (this.record.quantity || 0) * 1000,
                technology_operation_id: this.record.technology_operation_id  || datestr.uuid_nil(),

                project_id: this.record.project_id || datestr.uuid_nil(),

                department_id: this.record.department_id || datestr.uuid_nil(),
                man_quantity: (this.record.man_quantity || 0) * 1000

            }

            this.loading = true

            this.executeRequest(this.is_new ? 'insertrecord' : 'updaterecord', this.table, 'POST', () => {
            
                 this.executeRequest('postuppupr', { setStartMeasurement: this.table.record }, 'POST', () => {

                    this.loading = false

                    this.$router.push('/measurements')

                })
 
            })

        },

        onLineButtonClick(...arg){

            if (arg[0][1][0] == 'changeQuantity') {
            
                const curLine = this.products.filter(p => p.id == arg[0][0].id)[0]
                
                curLine.quantity += arg[0][1][1]

                if (curLine.quantity < 0) {
                    curLine.quantity = 0
                }
    
                curLine.sum = curLine.quantity * curLine.price

                this.sum = 0
                
                this.products.forEach(p => {

                    this.sum += p.sum

                })

            }

        },

        getFormSettings(){

            const formSettings = {

                title: 'Контракт' + (this.record.contract_contractor_id_str ? ' с ' + this.record.contract_contractor_id_str : '') + ' *',

                productList: [],
                techOpList: [],
                projectList: [],

                rows: [

                    { 
                        inputs: [ 
                            { 
                                class: 'input-field col s6', style: 'text-align: left; width: 80%; padding: 6px',
                                id: 'product_id_str', type:'text', label: 'Номенклатура', autocomplete: 'off', onKeyUp: (event) => { 
                                    
                                    const table = {
                                        name: tables.products.name,
                                        fields: tables.products.fields,
                                        accessFilter: ['name like @filter'],
                                        params: [ { name: 'filter', type: 'Char', value: '%' + event.target.value + '%' } ]
                                    }

                                    srvreq.fetchReqErr('gettable', table, 'POST', this.error, result => {
                                            this.formSettings.productList = result
                                    })

                                },
                                list: { id: 'productList', onClick: (item) => { 
                                
                                    this.record.product_id_str = item.name;
                                    this.record.product_id = item.id;
                                    this.formSettings.productList = [];

                                } }
                            },

                            { 
                                style: 'text-align: left; width: 20%; padding: 6px', id: 'quantity', type: 'number', label: 'Количество', autocomplete: 'off',
                                onKeyUp: () => {}
                            
                            }
                        ] 
                    },
                    { 
                        inputs: [ 
                            { 
                                style: 'text-align: left; width: 50%; padding: 6px',
                                id: 'technology_operation_id_str', type:'text', label: 'Тех.операция', autocomplete: 'off', onKeyUp: (event) => { 
                                    
                                    const table = {
                                        name: tables.technology_operations.name,
                                        fields: tables.technology_operations.fields,
                                        accessFilter: ['name like @filter'],
                                        params: [ { name: 'filter', type: 'Char', value: '%' + event.target.value + '%' } ]
                                    }

                                    srvreq.fetchReqErr('gettable', table, 'POST', this.error, result => {
                                            this.formSettings.techOpList = result
                                    })

                                },
                                list: { id: 'techOpList', onClick: (item) => { 
                                
                                    this.record.technology_operation_id_str = item.name;
                                    this.record.technology_operation_id = item.id;
                                    this.formSettings.techOpList = [];

                                } }
                            },
                            { 
                                style: 'text-align: left; width: 50%; padding: 6px',
                                id: 'project_id_str', type:'text', label: 'Проект', autocomplete: 'off', onKeyUp: (event) => { 
                                    
                                    const table = {
                                        name: tables.projects.name,
                                        fields: tables.projects.fields,
                                        accessFilter: ['name like @filter'],
                                        params: [ { name: 'filter', type: 'Char', value: '%' + event.target.value + '%' } ]
                                    }

                                    srvreq.fetchReqErr('gettable', table, 'POST', this.error, result => {
                                            this.formSettings.projectList = result
                                    })

                                },
                                list: { id: 'projectList', onClick: (item) => { 
                                
                                    this.record.project_id_str = item.name;
                                    this.record.project_id = item.id;
                                    this.formSettings.projectList = [];

                                } }
                            },

                        ] 
                    },
                    { 
                        inputs: [ 
                            { 
                                class: 'input-field col s6', style: 'text-align: left; width: 80%; padding: 6px',
                                id: 'department_id_str', type:'text', label: 'Цех', autocomplete: 'off', onKeyUp: (event) => { 
                                    
                                    const table = {
                                        name: tables.departments.name,
                                        fields: tables.departments.fields,
                                        accessFilter: ['name like @filter'],
                                        params: [ { name: 'filter', type: 'Char', value: '%' + event.target.value + '%' } ]
                                    }

                                    srvreq.fetchReqErr('gettable', table, 'POST', this.error, result => {
                                            this.formSettings.departmentsList = result
                                    })

                                },
                                list: { id: 'departmentsList', onClick: (item) => { 
                                
                                    this.record.department_id_str = item.name;
                                    this.record.department_id = item.id;
                                    this.formSettings.departmentsList = [];

                                } }
                            },

                            { 
                                style: 'text-align: left; width: 20%; padding: 6px', id: 'man_quantity', type: 'number', label: 'Количество человек', autocomplete: 'off',
                                onKeyUp: () => {}
                            
                            }
                        ] 
                    },
                ]

            }

            return formSettings

        },

    },
    components: { NavigationBar, ErrorBar, LoaderCircle, ListItems, InputFieldsView }
}

</script>