<template>

    <header>

        <NavigationBar v-bind:settings="nav_bar_settings" v-on:on-click-save-order="saveOrder()" />


    </header>



    <ErrorBar v-bind:is_error="is_error" v-bind:error_message="error_message" v-on:remove-error="is_error = false" />

    <div class="flex justify-between">
        <h6 class="text-left my-3 mx-2"><strong>Адрес доставки:</strong> {{ delivery_address }}</h6>

        <div id="datepicker-translated" class="relative my-3" data-te-datepicker-init data-te-format="dd.mm.yyyy"
            data-te-input-wrapper-init>
            <input v-model="shipping_date" data-te-input-state-active type="text"
                class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Дата отгрузки" />
            <label for="floatingInput"
                class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary">Дата
                отгрузки</label>
        </div>

        <h6 class="text-left my-3 mx-2"><strong>Сумма:</strong> {{ sum.toLocaleString('ru', {
            minimumFractionDigits: 2
            }) }}</h6>

    </div>

    <div class="relative mb-3 mx-2" data-te-input-wrapper-init>
        <textarea
            class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
            id="comment" rows="4" v-model="comment" placeholder="Комментарий" data-te-input-state-active
            data-te-input-focused></textarea>

        <label for="comment"
            class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary">Комментарий
        </label>
    </div>

    <div v-if="loading">
        <LoaderCircle />
    </div>
    <div v-else-if="products.length">

        <ListItems v-bind:fields="fields" v-bind:items="products" v-on:on-click-item="onClickItem"
            v-on:on-dblclick-item="onDblClickItem" v-on:on-line-button-click="onLineButtonClick" />

    </div>
    <p v-else>нет товаров</p>




</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'
import LoaderCircle from '@/components/LoaderCircle.vue';
import ListItems from '@/components/list/ListItems.vue';
import datestr from '@/datestr';

import tables from '@/tables';

import {
    Datepicker,
    Input,
    initTE,
} from "tw-elements"


export default {
    data() {
        return {
            user: {},
            user_settings: [],
            is_error: false,
            error_message: "",
            nav_bar_settings: {
                left: [navigation.home, navigation.orders],
                right: [
                    navigation.logout
                ]
            },

            table: tables.orders,

            loading: true,

            fields: [
                {
                    header: { text: '№', style: 'width: 2%;', class: 'text-center scrfull' },
                    item: { eval: 'item.line_number + 1', class: 'text-center scrfull' }
                },
                {
                    header: { text: 'Номенклатура', style: '', class: 'text-center scrfull' },
                    item: { eval: 'item.product_id_str + \' ( \' + item.base_unit_name + \' )\'', class: '\'font-bold\': item.quantity > 0\'  scrfull' }
                },
                {
                    header: { text: 'Номенклатура', style: '', class: 'text-center scr1024' },
                    item: {
                        eval: '(item.line_number + 1) + \', \' + item.product_id_str + \' ( \' + item.base_unit_name + \' )\'',
                        class: '\'font-bold\': item.quantity > 0\' text-center  scr1024'
                    }
                },
                {
                    header: { text: 'Цена за шт', style: '', class: 'text-center scrfull' },
                    item: { eval: 'item.price', class: 'text-center scrfull' }
                },
                {
                    header: { text: 'Упак', style: '', class: 'text-center scrfull' },
                    item: { eval: 'item.unit_id_str + \' ( \' + item.coefficient + \' \' + item.base_unit_name + \' )\'', class: 'text-center scrfull' }
                },
                {
                    header: { text: 'Цена за упак', style: '', class: 'text-center scrfull' },
                    item: { eval: 'item.unit_price', class: 'text-center scrfull' }
                },
                {
                    header: { text: 'Цена за шт', style: '', class: 'text-center scr1024' },
                    item: {
                        eval: '\'Цена за шт: \' + item.price + \', упак: \' + item.unit_id_str + \', цена за упак: \' + item.unit_price',
                        class: 'text-center scr1024'
                    }
                },
                {
                    header: { text: '', style: 'width: 2%;', class: 'text-center scrfull' },
                    item: {
                        html_pred: '\'<button type="button" '
                            + 'class="scrfull rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            + '-</button>\'',
                        html_pred_click: ['changeQuantity', -1],
                        class: 'text-center scrfull',
                    }
                },
                {
                    header: { text: 'Количество', style: 'width: 2%;', class: 'text-center scrfull' },
                    item: {
                        eval: 'item.quantity + \' (\' + item.unit_id_str + \') \' + item.quantity * item.coefficient + \' (\' + item.base_unit_name + \') \'',
                        class: 'text-center scrfull',
                    }
                },
                {
                    header: { text: 'Количество', style: 'width: 2%;', class: 'text-center scr1024flex' },
                    item: {
                        html_pred: '\'<div class="flex px-6"><button type="button" '
                            + 'class="inline-block rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            + '-</button></div>\'',
                        html_pred_click: ['changeQuantity', -1],
                        eval: 'item.quantity + \' (\' + item.unit_id_str + \') \' + item.quantity * item.coefficient + \' (\' + item.base_unit_name + \') \'',
                        class: 'text-center scr1024flex m-auto px-4',
                        html_post: '\'<div class="flex px-6"><button type="button" '
                            + 'class="inline-block rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            + '+</button></div>\'',
                        html_post_click: ['changeQuantity', 1]
                    }
                },
                {
                    header: { text: '', style: 'width: 2%;', class: 'text-center scrfull' },
                    item: {
                        html_post: '\'<button type="button" '
                            + 'class="scrfull rounded bg-success px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">'
                            + '+</button>\'',
                        html_post_click: ['changeQuantity', 1],
                        class: 'text-center scrfull',
                    }
                },
            ],

            id: '',

            contractor_address_ln: null,

            is_new: true,

            date: '',

            shipping_date: '',

            contractor_id: '',
            delivery_address: '',

            posted: false,
            is_deleted: false,

            comment: '',

            sum: 0,
            minSum: 0,

            products: []

        };
    },
    mounted() {

        initTE({ Datepicker, Input })

        this.datepickerTranslated = new Datepicker(
            document.querySelector("#datepicker-translated"),
            {
                title: "Введите дату",
                monthsFull: [
                    "Январь",
                    "Февраль",
                    "Март",
                    "Апрель",
                    "Май",
                    "Июнь",
                    "Июль",
                    "Август",
                    "Сентябрь",
                    "Октябрь",
                    "Ноябрь",
                    "Декабрь",
                ],
                monthsShort: [
                    "Янв",
                    "Фев",
                    "Мар",
                    "Апр",
                    "Май",
                    "Июн",
                    "Июл",
                    "Авг",
                    "Сен",
                    "Окт",
                    "Ноя",
                    "Дек",
                ],
                weekdaysFull: [
                    "Воскресенье",
                    "Понедельник",
                    "Вторник",
                    "Среда",
                    "Четверг",
                    "Пятница",
                    "Суббота",
                ],
                weekdaysShort: ["Вос", "Пон", "Вто", "Сре", "Чет", "Пят", "Суб"],
                weekdaysNarrow: ["В", "П", "В", "С", "Ч", "П", "С"],
                okBtnText: "Ок",
                clearBtnText: "Очистить",
                cancelBtnText: "Отмена",
            }
        );

        this.contractor_address_ln = this.$route.query.ln

        const in_id = this.$route.query.id

        this.id = in_id ? in_id : datestr.uuid4()
        this.is_new = in_id ? false : true

        settings.getUserSettings((err, data) => {

            if (err) {
                this.is_error = true;
                this.error_message = data;
            }
            else {

                this.user = data.user

                this.user_settings = data.user_settings;

                if (this.is_new) {

                    this.shipping_date = ''

                    this.getSettingsProducts(false)

                } else {

                    this.table.accessFilter = ['orders.id = @order_id']
                    this.table.params = [{ name: 'order_id', type: 'Char', value: this.id }]

                    this.executeRequest('gettable', this.table, 'POST', (result) => {

                        this.date = result[0].date
                        this.contractor_id = result[0].contractor_id
                        this.delivery_address = result[0].delivery_address

                        this.sum = Math.round(result[0].sum / 100)

                        this.posted = result[0].posted
                        this.is_deleted = result[0].is_deleted
                        this.comment = result[0].comment

                        this.shipping_date = result[0].shipping_date === '        ' ? '' : result[0].shipping_date.substr(6, 2) + '.' + result[0].shipping_date.substr(4, 2) + '.' + result[0].shipping_date.substr(0, 4)

                        this.getSettingsProducts()

                    })

                }


            }
        })

    },
    methods: {

        getSettingsProducts() {

            if (!this.posted && settings.getSettingEnabled(this.user_settings, 'edit_orders')) {

                this.nav_bar_settings.left.push(navigation.save_order)

            }

            const table = {
                name: tables.product_set_users.name,
                fields: tables.product_set_users.fields,
                accessFilter: ['user_id = @user_id'],
                params: [{ name: 'user_id', type: 'Char', value: this.user.token }]
            }

            if (this.contractor_address_ln) {

                table.accessFilter.push('line_number = @line_number')
                table.params.push({ name: 'line_number', type: 'SmallInt', value: this.contractor_address_ln })

            }

            this.executeRequest('gettable', table, 'POST', result => {

                if (!result.length) {

                    this.is_error = true;
                    this.error_message = 'user sets empty';
                } else {

                    this.contractor_id = result[0].contractor_id
                    this.delivery_address = result[0].address_points_address

                    const tableProductSet = {
                        name: tables.product_sets.name,
                        fields: tables.product_sets.fields,
                        accessFilter: ['id = @doc_id'],
                        params: [{ name: 'doc_id', type: 'Char', value: result[0].doc_id }],
                    }

                    this.executeRequest('gettable', tableProductSet, 'POST', result => {

                        if (!result.length) {

                            this.is_error = true;
                            this.error_message = 'user sets empty';
                        } else {

                            this.minSum = Math.round(result[0].min_sum / 100)

                            const product_table = {
                                name: tables.product_set_products.name,
                                fields: tables.product_set_products.fields,

                                joins: tables.product_set_products.joins,

                                accessFilter: ['doc_id = @doc_id'],
                                params: [{ name: 'doc_id', type: 'Char', value: result[0].id }],

                                top: 100,

                                order: ['line_number']
                            }

                            this.executeRequest('gettable', product_table, 'POST', result => {

                                let line_number = 0

                                result.forEach(line => {

                                    this.products.push({
                                        id: datestr.uuid4(),
                                        doc_id: this.id,
                                        line_number: line_number,
                                        product_id: line.product_id,
                                        product_id_str: line.name,
                                        price: Math.round(line.price) / 100,
                                        characteristic_id: datestr.uuid_nil(),
                                        base_unit_name: line.base_unit_name,
                                        unit_id_str: line.unit_id_str,
                                        unit_price: Math.round(line.unit_price) / 100,
                                        coefficient: Math.round(line.coefficient) / 1000,
                                        quantity: 0,
                                        sum: 0
                                    })

                                    line_number = line_number + 1

                                });

                                const order_products = this.table.tables.filter(t => t.name == 'order_products')[0]

                                order_products.accessFilter = ['doc_id = \'' + this.id + '\'']

                                this.executeRequest('gettable', order_products, 'POST', (result) => {

                                    result.forEach(r => {

                                        const curLine = this.products.filter(p => p.product_id == r.product_id)[0]

                                        curLine.quantity = Math.round(r.quantity / 1000) * Math.round((Number(r.coefficient) == 1 ? 1000 : r.coefficient) / 1000) / curLine.coefficient
                                        curLine.sum = curLine.quantity * curLine.unit_price

                                    })

                                    if (this.posted) {

                                        this.products = this.products.filter(p => p.quantity != 0)

                                    }

                                    this.loading = false

                                })
                            })
                        }
                    })
                }

            })


        },

        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.is_error = true;
                    this.error_message = result;
                }
                else {
                    callback(result);
                }
            });
        },

        saveOrder() {

            let curSum = 0
            this.products.forEach(p => {

                if (p.quantity > 0) {
                    curSum += p.quantity * p.price
                }

            })

            if (curSum < this.minSum) {
                this.is_error = true;
                this.error_message = 'минимальная сумма ' + this.minSum + ' руб';
            }
            else {

                this.products.forEach(p => {

                    if (p.quantity > 0) {
                        //curSum += p.quantity * p.price
                        p.quantity *= 1000
                        //p.coefficient *= 1000
                    }

                })


                this.table.record = {

                    id: this.id,
                    date: this.is_new ? datestr.dateToYMDHMS(new Date()) : this.date,
                    is_deleted: this.is_deleted,
                    contractor_id: this.contractor_id,
                    sum: curSum * 100,
                    comment: this.comment,
                    author_id: this.user.token,
                    posted: this.posted,
                    delivery_address: this.delivery_address,

                    shipping_date: this.shipping_date.length < 10 ? '' : this.shipping_date.substr(6, 4) + this.shipping_date.substr(3, 2) + this.shipping_date.substr(0, 2),

                    order_products: this.products.filter(p => p.quantity > 0)

                }

                this.loading = true

                this.executeRequest(this.is_new ? 'insertrecord' : 'updaterecord', this.table, 'POST', (result) => {

                    this.items = result

                    this.table.record.sum = Math.round(this.table.record.sum) / 100

                    this.table.record.order_products.forEach(op => {
                        op.quantity = Math.round(op.quantity / 1000) * op.coefficient
                    })

                    this.executeRequest('postuppupr', { setOrder: this.table.record }, 'POST', result => {

                        this.items = result

                        this.loading = false

                        this.$router.push('/orders')

                    })

                })
            }
        },

        onLineButtonClick(...arg) {

            if (arg[0][1][0] == 'changeQuantity') {

                const curLine = this.products.filter(p => p.id == arg[0][0].id)[0]

                curLine.quantity += arg[0][1][1]

                if (curLine.quantity < 0) {
                    curLine.quantity = 0
                }

                curLine.sum = curLine.quantity * curLine.coefficient * curLine.price

                this.sum = 0

                this.products.forEach(p => {

                    this.sum += p.sum

                })

            }

        }

    },
    components: { NavigationBar, ErrorBar, LoaderCircle, ListItems }
}

</script>