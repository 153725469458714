<template>

        <NavigationBar
            v-bind:settings="nav_bar_settings"
        />


    <h2 class="text-2xl font-medium leading-tight mt-3 text-left">
        <span
            class="inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"
            >Пользователь</span
        >
        {{ user.name }}
    </h2>

    <h2 v-if="contractor_id_str" class="text-2xl font-medium leading-tight mt-3 text-left">
        <span
            class="inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"
            >Контрагент</span
        >
        {{ contractor_id_str }}
    </h2>

    <h2 v-if="delivery_address" class="text-2xl font-medium leading-tight mt-3 text-left">
        <span
            class="inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"
            >Адрес доставки</span
        >
        {{ delivery_address }}
    </h2>

<ErrorBar
        v-bind:is_error="error.visible"
        v-bind:error_message="error.message"
        v-on:remove-error="error.visible = false"      

/>


</template>

<script>

import srvreq from '@/srvreq.js'

import ErrorBar from "@/components/ErrorBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";

import navigation from '@/navigation.js'

import settings from '@/settings.js'
import tables from '@/tables.js'

export default {
    data() {
        return {
            user: {},
            user_settings: [],
            error: {
                visible: false,
                message: ""
            },
            nav_bar_settings: {
              left: [],
              right: [ 
                navigation.logout
                ]              
            },

            contractor_id_str: '',
            delivery_address: ''
        };
    },
    mounted() {

      settings.getUserSettings((err, data) => {

        if (err) {
                    this.error.visible = true;
                    this.error.message = data;
        }
        else {
          this.user = data.user

          this.user_settings = data.user_settings;

          if (settings.getSettingEnabled(this.user_settings, 'admining')) {

            this.nav_bar_settings.left.push( navigation.admining )
            
          }

          const ordersSettings = settings.getSettingEnabled(this.user_settings, 'edit_orders') 
            || settings.getSettingEnabled(this.user_settings, 'view_orders')

          if (ordersSettings) {

            this.nav_bar_settings.left.push( navigation.orders )
            
          }

          if (settings.getSettingEnabled(this.user_settings, 'measurements')) {

            this.nav_bar_settings.left.push( navigation.measurements )
            
          }

          const table = {
                name: tables.product_set_users.name,
                fields: tables.product_set_users.fields,
                accessFilter: ['user_id = @user_id'],
                params: [ { name: 'user_id', type: 'Char', value: this.user.token } ]
            }

          this.executeRequest('gettable', table, 'POST', result => {

            if (!result.length) {
                
                if (ordersSettings) {
                    
                    this.error.visible = true;
                    this.error.message = 'user sets empty';

                }

            } else {

                if (result.length == 1) {
                    
                    this.contractor_id_str = result[0].contractor_id_str
                    this.delivery_address = result[0].address_points_address
                }


            }
            })




        }
      })

    },
    methods: {
        executeRequest(url, data, method, callback) {
            srvreq.fetchReq(url, data, method, (err, result) => {
                if (err) {
                    this.error.visible = true;
                    this.error.message = result;
                }
                else {
                    callback(result);
                }
            });
        },
        Logout() {
            localStorage.clear();
            this.$router.push("/login");
        },


    },
    components: { ErrorBar, NavigationBar }
}
</script>
