import srvreq from "./srvreq";

import tables from "./tables";

function getUserSettings(callback) {

    const user = JSON.parse(localStorage.getItem('user'))

    const table = tables.user_settings
    table.accessFilter = ["user_id = '" + user.token + "'"]
   
    
    srvreq.fetchReq('gettable', table, 'POST', (err, result) => {

        callback(err, { user: user, user_settings: result } )


    });
}

function getSettingEnabled(user_settings, setting_name){

  const curSetting = user_settings.filter(s => s.name == setting_name)

  return curSetting.length && curSetting[0].value == '1'

}

function getSettingValue(user_settings, setting_name){

  const curSetting = user_settings.filter(s => s.name == setting_name)

  return curSetting.length ? curSetting[0].value : null

}

export default {

    getUserSettings,
    getSettingValue,
    getSettingEnabled

}