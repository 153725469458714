import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import Page404View from '../views/Page404View.vue'
import OrdersView from '../views/OrdersView.vue'
import OrderRecordView from '../views/OrderRecordView.vue'
import SelectAddressView from '../views/SelectAddressView.vue'
import MeasurementsView from '../views/MeasurementsView.vue'
import MeasurementRecordView from '../views/MeasurementRecordView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/measurements',
    name: 'measurements',
    component: MeasurementsView,
     meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/measurementrecord',
    name: 'measurementrecord',
    component: MeasurementRecordView,
     meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
     meta: { 
      requiresAuth: true
    }
  },
   {
    path: '/orderrecord',
    name: 'orderrecord',
    component: OrderRecordView,
     meta: { 
      requiresAuth: true
    }
  },
   {
    path: '/selectaddress',
    name: 'selectaddress',
    component: SelectAddressView,
     meta: { 
      requiresAuth: true
    }
  },
 {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/404',
    name: '404',
    component: Page404View
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { 
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if(routes.find(route => route.path === to.path)){

    const user = JSON.parse(localStorage.getItem('user') || '{}')

    if(to.meta.requiresAuth && !user.token){
    
      next('/login') 
    
    } else {
    
      next()
    
    }

  } else  {

    next('/404')
    
  }
  
})




export default router
